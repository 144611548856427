<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useRoot } from '~/store'

const { width, height, className } = defineProps<{
  width?: string
  height?: string
  className?: string
}>()

const { theme } = storeToRefs(useRoot())
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width || '184'"
    :height="height || '36'"
    fill="none"
    viewBox="0 0 184 36"
    :class="className || ''"
  >
    <g :fill="theme?.hexPrimary || '#ffffff'">
      <path
        :stroke="theme?.hexSecondary || '#ffffff'"
        stroke-miterlimit="10"
        stroke-width=".5"
        d="M13.6.322H.01v35.137H13.6c9.682 0 17.56-7.87 17.56-17.545C31.16 8.213 23.282.322 13.6.322zm0 33.262H1.842V2.197H13.6c8.672 0 15.727 7.051 15.727 15.717 0 8.64-7.055 15.668-15.727 15.668v.002zM109.194 16.49l-.053.178.053-.179c-4.79-1.483-9.313-2.884-9.313-8.13 0-3.743 3.639-6.164 9.27-6.164 2.917 0 6.126.876 8.178 2.23l.374.247.943-1.545-.363-.241C115.923 1.328 112.337.32 109.15.32c-6.745 0-11.103 3.156-11.103 8.04 0 6.642 5.361 8.303 10.546 9.912 4.88 1.514 9.489 2.944 9.445 8.412 0 4.191-3.902 6.899-9.941 6.899-3.894 0-7.992-1.714-10.442-4.366l-.31-.335-1.296 1.328.305.317c2.878 2.996 7.488 4.932 11.743 4.932 7.151 0 11.773-3.444 11.773-8.774.052-6.875-5.402-8.563-10.676-10.195zM68.18.32v1.877h19.944v14.24H70.38v1.876h17.744v15.269H67.511v1.878h22.446V.32H68.18zM40.591 33.582V18.313h17.744v-1.877H40.59V2.196h19.944V.32H38.759v35.14h22.444v-1.878H40.591zM66.714 8.683h-4.629v1.752h4.629V8.683zM66.714 25.344h-4.629v1.753h4.629v-1.753z"
      />
      <path
        d="M149.827 0v21.05c0 7.436-4.044 11.701-11.093 11.701-7.05 0-11.093-4.266-11.093-11.7V8.496h-2.958V21.05c0 9.362 5.121 14.731 14.051 14.731 8.929 0 14.051-5.369 14.051-14.73V0h-2.958zM169.69 0h-12.168v35.781h2.958V3.03h9.21c6.751 0 10.623 3.263 10.623 8.952 0 5.843-3.872 9.193-10.623 9.193h-6.083v3.03h6.083c8.63 0 13.58-4.455 13.58-12.223C183.27 4.367 178.32 0 169.69 0z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path :fill="theme?.hexSecondary || '#fff'" d="M0 0H183.27V35.78H0z" />
      </clipPath>
    </defs>
  </svg>
</template>
