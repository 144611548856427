<script lang="ts" setup>
// @ts-expect-error JS library
import { useMq } from 'vue3-mq'
import { vue3dLoader } from 'vue-3d-loader'

const cameraPosition = { x: 0, y: 0, z: 2.4 }

const { xs, sm, md, lg, xl, xxl } = useMq()

const rotation = ref()
rotation.value = {
  x: 0.4,
  y: 0,
  z: 0,
}

const height = computed((): number => (xs ? 275 : sm ? 350 : md || lg ? 425 : xl ? 500 : 700))

function onLoad() {
  rotate()
}

function rotate() {
  requestAnimationFrame(rotate)
  rotation.value.y -= 0.02
}
</script>

<template lang="pug">
section.home__gamma
  UI3DModel(
    :path="'/3d/coding_desk_3D.glb'"
    :show-fps="false"
    :background-color="0xFFFFFF"
    :background-alpha="0"
    :height="height"
    :figcaption="$t('home.gamma.figcaption')"
    @load="onLoad()"
  )
  .home__gamma__content
    h4 {{ $t('home.gamma.title') }}
    p {{ $t('home.gamma.text-content') }}
</template>

<style lang="sass" scoped>
.home__gamma
  @apply w-full h-full flex-center flex-col lg:flex-row
  .home__gamma__3d-model
    @apply flex-grow-[1]
  .home__gamma__content
    @apply w-full max-w-[500px]
    h4
      @apply text-lg lg:text-xl xl:text-3xl text-center lg:text-left text-animated font-bold
    p
      @apply text-xs xl:text-base text-white mt-4 text-center lg:text-left
</style>
