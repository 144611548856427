<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useRadar } from '~/store/radar'
import HomeAlpha from '~/components/page/home/Alpha.vue'
import HomeBeta from '~/components/page/home/Beta.vue'
import HomeGamma from '~/components/page/home/Gamma.vue'
import HomeDelta from '~/components/page/home/Delta.vue'
import HomeEpsilon from '~/components/page/home/Epsilon.vue'

const page = 'index'

const { t } = useI18n()
const radar = useRadar()
const { relCanonical, relAlternates } = useMetaRel()

useSeoMeta({
  title: () => t(`routes.${page}.title`),
  ogTitle: () => t(`routes.${page}.title`),
  description: () => t(`routes.${page}.description`),
  ogDescription: () => t(`routes.${page}.description`),
})

useHead({
  link: [relCanonical, ...relAlternates],
})

useStructuredData(page)

const { routeComponents } = storeToRefs(radar)

const components = [HomeAlpha, HomeBeta, HomeGamma, HomeDelta, HomeEpsilon]

radar.setRouteComponents(components)
</script>

<template lang="pug">
LayoutGrid(:class="page")
  template(
    v-for="(component, index) in routeComponents"
    :key="`component-${index}`"
    #[component?.letter?.word]
  )
    component(:is="component.component")
</template>
