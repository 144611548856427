<!-- eslint-disable vue/one-component-per-file -->
<script lang="tsx" setup>
// @ts-expect-error JS library
import { useMq } from 'vue3-mq'
import { Atropos } from 'atropos/vue'
import type { LordIcon } from '~/types/assets'
import { LordIconSource, LordIconTrigger } from '~/types/assets'
import type { List } from '~/types/custom'
import { useLocaleThemePath } from '~/composables/locale'
import IconLord from '~/components/icon/Lord.vue'

const { xs } = useMq()
const { t } = useI18n()

// necessary for SSR
const isMobile = ref(false)

const list = computed((): List => {
  const items = [
    {
      id: 1,
      title: t('home.delta.cards.0.title'),
      textContent: t('home.delta.cards.0.text-content'),
      iconComponent: markRaw(
        defineComponent({
          render: () => (
            <IconLord
              src={LordIconSource.webDesign}
              trigger={LordIconTrigger.loop}
              revert={true}
              size={65}
              delay={500}
              class="lord-icon"
              loading="lazy"
            />
          ),
        }),
      ),
    },
    {
      id: 2,
      title: t('home.delta.cards.1.title'),
      textContent: t('home.delta.cards.1.text-content'),
      iconComponent: markRaw(
        defineComponent({
          render: () => (
            <IconLord
              src={LordIconSource.webApp}
              trigger={LordIconTrigger.loop}
              revert={true}
              size={65}
              delay={500}
              class="lord-icon"
              loading="lazy"
            />
          ),
        }),
      ),
    },
    {
      id: 3,
      title: t('home.delta.cards.2.title'),
      textContent: t('home.delta.cards.2.text-content'),
      iconComponent: markRaw(
        defineComponent({
          render: () => (
            <IconLord
              src={LordIconSource.cms}
              trigger={LordIconTrigger.loop}
              revert={true}
              size={65}
              delay={500}
              class="lord-icon"
              loading="lazy"
            />
          ),
        }),
      ),
    },
    {
      id: 4,
      title: t('home.delta.cards.3.title'),
      textContent: t('home.delta.cards.3.text-content'),
      iconComponent: markRaw(
        defineComponent({
          render: () => (
            <IconLord
              src={LordIconSource.servers}
              trigger={LordIconTrigger.loop}
              revert={true}
              size={65}
              delay={500}
              class="lord-icon"
              loading="lazy"
            />
          ),
        }),
      ),
    },
    {
      id: 5,
      title: t('home.delta.cards.5.title'),
      textContent: t('home.delta.cards.5.text-content'),
      iconComponent: markRaw(
        defineComponent({
          render: () => (
            <IconLord
              src={LordIconSource.seo}
              trigger={LordIconTrigger.loop}
              revert={true}
              size={65}
              delay={500}
              class="lord-icon"
              loading="lazy"
            />
          ),
        }),
      ),
    },
    {
      id: 6,
      title: t('home.delta.cards.6.title'),
      textContent: t('home.delta.cards.6.text-content'),
      iconComponent: markRaw(
        defineComponent({
          render: () => (
            <IconLord
              src={LordIconSource.robot}
              trigger={LordIconTrigger.loop}
              revert={true}
              size={65}
              delay={500}
              class="lord-icon"
              loading="lazy"
            />
          ),
        }),
      ),
    },
    {
      id: 7,
      title: t('home.delta.cards.8.title'),
      textContent: t('home.delta.cards.8.text-content'),
      iconComponent: markRaw(
        defineComponent({
          render: () => (
            <IconLord
              src={LordIconSource.analytics}
              trigger={LordIconTrigger.loop}
              revert={true}
              size={65}
              delay={500}
              class="lord-icon"
              loading="lazy"
            />
          ),
        }),
      ),
    },
    {
      id: 8,
      title: t('home.delta.cards.9.title'),
      textContent: t('home.delta.cards.9.text-content'),
      iconComponent: markRaw(
        defineComponent({
          render: () => (
            <IconLord
              src={LordIconSource.treasure}
              trigger={LordIconTrigger.loop}
              revert={true}
              size={65}
              delay={500}
              class="lord-icon"
              loading="lazy"
            />
          ),
        }),
      ),
    },
  ]

  if (!isMobile.value) {
    items.push({
      id: 9,
      title: t('home.delta.cards.4.title'),
      textContent: t('home.delta.cards.4.text-content'),
      iconComponent: markRaw(
        defineComponent({
          render: () => (
            <IconLord
              src={LordIconSource.microscope}
              trigger={LordIconTrigger.loop}
              revert={true}
              size={65}
              delay={500}
              class="lord-icon"
              loading="lazy"
            />
          ),
        }),
      ),
    })
    items.push({
      id: 10,
      title: t('home.delta.cards.7.title'),
      textContent: t('home.delta.cards.7.text-content'),
      iconComponent: markRaw(
        defineComponent({
          render: () => (
            <IconLord
              src={LordIconSource.loadBalancer}
              trigger={LordIconTrigger.loop}
              revert={true}
              size={65}
              delay={500}
              class="lord-icon"
              loading="lazy"
            />
          ),
        }),
      ),
    })
  }

  return items
})

onMounted(() => {
  if (xs) isMobile.value = true
})
</script>

<template lang="pug">
section.home__delta
  h5 {{ $t('home.delta.title') }}
  ul(v-if="!isMobile && list")
    li(
      v-for="(item, index) in list"
      :key="`list-item-${index}`"
    )
      Atropos(
        :rotate="true"
        :active-offset="40"
        :shadow-scale="1.05"
      )
        .atropos__wrapper
          span {{ item.title }}
          Component(
            :is="item.iconComponent"
            v-if="item?.iconComponent"
          )
          p {{ item.textContent }}
  ul(v-else)
    li(
      v-for="(item, index) in list"
      :key="`list-item-${index}`"
    )
      span {{ item.title }}
      Component(
        :is="item.iconComponent"
        v-if="item?.iconComponent"
      )
      p {{ item.textContent }}
</template>

<style lang="sass" scoped>
.home__delta
  @apply w-full h-full flex-center flex-col md:px-8
  h5
    @apply text-primary text-2xl xl:text-4xl mb-6 text-center text-animated font-bold
  ul
    @apply w-full lg:w-10/12 grid grid-cols-4 lg:grid-cols-5 place-content-center gap-2 md:gap-4 justify-center
    li
      @apply cursor-pointer flex-center flex-col
      filter: drop-shadow(0 0 3rem #525252)
      .atropos__wrapper
        @apply w-full h-full flex-center flex-col p-2 text-white border border-primary text-center bg-dark-900 rounded-md text-sm lg:text-base
      .atropos
        @apply h-full
      span
        @apply text-white xl:text-secondary text-sm text-center
      p
        @apply hidden xl:block text-white text-[11px] leading-[13px] text-center
</style>
