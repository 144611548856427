<script lang="ts" setup>
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { vue3dLoader } from 'vue-3d-loader'
import type { LordIcon } from '~/types/assets'
import { LordIconSource, LordIconTrigger } from '~/types/assets'

const props = defineProps({
  path: {
    type: String,
    default: '',
  },
  showFps: {
    type: Boolean,
    default: false,
  },
  backgroundColor: {
    type: Number,
    /* prettier-ignore */
    default: 0xFFFFFF,
  },
  backgroundAlpha: {
    type: Number,
    default: 0,
  },
  height: {
    type: Number,
    default: 600,
  },
  figcaption: {
    type: String,
    default: null,
  },
})

const modelRef: Ref<(typeof vue3dLoader & HTMLElement) | null> = ref(null)
const modelVisible = ref(false)
const modelLoaded = ref(false)
const rotation = reactive({
  x: 0.4,
  y: 0,
  z: 0,
})
const cameraPosition = { x: 0, y: 0, z: 2.4 }
const loaderIcon: LordIcon = {
  src: LordIconSource?.loaderSpyral,
  trigger: LordIconTrigger?.loop,
  revert: true,
  delay: 500,
  size: 100,
  loading: 'lazy',
}

function onLoad() {
  modelLoaded.value = true
  rotate()
}

function rotate() {
  requestAnimationFrame(rotate)
  rotation.y -= 0.02
}

onMounted(() => {
  // Ensures that the modelRef is set before trying to add the IntersectionObserver to it
  nextTick(() => {
    if (modelRef.value) {
      const observer = new IntersectionObserver(
        (entries) => {
          const entry = entries[0]
          if (entry.isIntersecting) {
            observer.unobserve(entry.target)
            modelVisible.value = true
          }
        },
        { threshold: 0.5 },
      )
      observer.observe(modelRef.value)
    }
  })
})
</script>

<template lang="pug">
ClientOnly
  figure.model-3d-wrapper
    .model-3d-loader(
      ref="modelRef"
      :style="`height: ${height}px;min-width: ${height}px;`"
    )
      Transition(name="fade")
        vue3dLoader(
          v-if="modelVisible"
          :file-path="path"
          :show-fps="showFps"
          :background-color="backgroundColor"
          :background-alpha="backgroundAlpha"
          :rotation="rotation"
          :camera-position="cameraPosition"
          style="width:100%; height:100%;"
          @load="onLoad()"
        )
      Transition(name="fade")
        .model-3d-placeholder(v-if="!modelLoaded")
          IconLord(v-bind="loaderIcon")
          p {{ $t('home.gamma.model-placeholder') }}
    figcaption(
      v-if="figcaption"
    ) {{ figcaption  }}
</template>

<style lang="sass" scoped>
figure.model-3d-wrapper
  @apply w-full relative
  .model-3d-loader
    .model-3d-placeholder
      @apply absolute top-0 left-0 right-0 bottom-0 flex-center flex-col
      p
        @apply text-lg text-primary text-center font-bold
  figcaption
    @apply hidden lg:block absolute bottom-0 left-[50%] bg-transparent-dark text-primary border-2 border-primary px-1 py-0.5 rounded-lg shadow-xl text-[12px] max-w-[250px] text-center translate-x-[-50%]
</style>
