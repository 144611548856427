<script lang="tsx" setup>
// @ts-expect-error JS library
import { useMq } from 'vue3-mq'
import type { Timeline } from '~/types/custom'
import type { LordIcon } from '~/types/assets'
import { LordIconSource, LordIconTrigger, MDIIcon } from '~/types/assets'
import { useLocaleThemePath } from '~/composables/locale'
import IconBoolean from '~/components/icon/Boolean.vue'
import IconVendure from '~/components/icon/Vendure.vue'
import IconLord from '~/components/icon/Lord.vue'
import IconDeesup from '~/components/icon/Deesup.vue'
import IconUdemy from '~/components/icon/Udemy.vue'
import IconYoutube from '~/components/icon/Youtube.vue'
import IconWAD from '~/components/icon/WAD.vue'

const UnoIcon = resolveComponent('UnoIcon')

const { xs, sm } = useMq()
const { t } = useI18n()

const timelineEl: Ref = ref(null)
// necessary for SSR
const isMobile = ref(false)
const nuxtLink = resolveComponent('NuxtLink')

const timeline: Timeline = [
  {
    date: '2018-04-01',
    title: t('home.epsilon.timeline.events.cisco-stage.title'),
    description: t('home.epsilon.timeline.events.cisco-stage.description'),
    // @ts-expect-error type mismatch
    logo: () => <UnoIcon class={`${MDIIcon?.cisco} text-6xl text-secondary`} />,
    anchor: {
      to: useLocaleThemePath('/certs/cisco-stage'),
      title: t('home.epsilon.timeline.events.cisco-stage.anchor.title'),
      'aria-label': t('home.epsilon.timeline.events.cisco-stage.anchor.aria-label')
    }
  },
  {
    date: '2019-01-01',
    title: t('home.epsilon.timeline.events.cisco-ite.title'),
    description: t('home.epsilon.timeline.events.cisco-ite.description'),
    // @ts-expect-error type mismatch
    logo: () => <UnoIcon class={`${MDIIcon?.cisco} text-6xl text-secondary`} />,
    anchor: {
      to: useLocaleThemePath('/certs/ite'),
      title: t('home.epsilon.timeline.events.cisco-ite.anchor.title'),
      'aria-label': t('home.epsilon.timeline.events.cisco-ite.anchor.aria-label')
    }
  },
  {
    date: '2019-05-01',
    title: t('home.epsilon.timeline.events.cisco-ccna.title'),
    description: t('home.epsilon.timeline.events.cisco-ccna.description'),
    // @ts-expect-error type mismatch
    logo: () => <UnoIcon class={`${MDIIcon?.cisco} text-6xl text-secondary`} />,
    anchor: {
      to: useLocaleThemePath('/certs/ccna'),
      title: t('home.epsilon.timeline.events.cisco-ccna.anchor.title'),
      'aria-label': t('home.epsilon.timeline.events.cisco-ccna.anchor.aria-label')
    }
  },
  {
    date: '2019-06-17',
    title: t('home.epsilon.timeline.events.high-school.title'),
    description: t('home.epsilon.timeline.events.high-school.description'),
    logo: () => (
      <IconLord
        src={LordIconSource.school}
        trigger={LordIconTrigger?.loop}
        revert={false}
        delay={500}
        size={65}
        loading="lazy"
      />
    ),
    anchor: {
      href: 'https://www.einsteinvimercate.edu.it/home/',
      rel: 'nofollow',
      title: t('home.epsilon.timeline.events.high-school.anchor.title'),
      'aria-label': t('home.epsilon.timeline.events.high-school.anchor.aria-label'),
      target: '_blank'
    }
  },
  {
    date: '2021-08-01' as unknown as Date,
    title: t('home.epsilon.timeline.events.boolean-course.title'),
    description: t('home.epsilon.timeline.events.boolean-course.description'),
    logo: () => <IconBoolean className="w-12 h-12" lazy={true} />,
    anchor: {
      href: 'https://boolean.careers/',
      rel: 'nofollow',
      title: t('home.epsilon.timeline.events.boolean-course.anchor.title'),
      'aria-label': t('home.epsilon.timeline.events.boolean-course.anchor.aria-label'),
      target: '_blank'
    }
  },
  {
    date: '2022-02-01' as unknown as Date,
    title: t('home.epsilon.timeline.events.boolean-cert.title'),
    description: t('home.epsilon.timeline.events.boolean-cert.description'),
    logo: () => <IconBoolean className="w-12 h-12" lazy={true} />,
    anchor: {
      to: useLocaleThemePath('/certs/boolean'),
      title: t('home.epsilon.timeline.events.boolean-cert.anchor.title'),
      'aria-label': t('home.epsilon.timeline.events.boolean-cert.anchor.aria-label')
    }
  },
  {
    date: '2022-02-01' as unknown as Date,
    title: t('home.epsilon.timeline.events.deesup.title'),
    description: t('home.epsilon.timeline.events.deesup.description'),
    logo: () => <IconDeesup width="100" />,
    anchor: {
      href: 'https://www.deesup.com',
      rel: 'nofollow',
      title: t('home.epsilon.timeline.events.deesup.anchor.title'),
      'aria-label': t('home.epsilon.timeline.events.deesup.anchor.aria-label'),
      target: '_blank'
    }
  },
  {
    date: '2022-09-01' as unknown as Date,
    title: t('home.epsilon.timeline.events.deesup-release.title'),
    description: t('home.epsilon.timeline.events.deesup-release.description'),
    logo: () => <IconDeesup width="100" />
  },
  {
    date: '2023-01-01' as unknown as Date,
    title: t('home.epsilon.timeline.events.host.title'),
    description: t('home.epsilon.timeline.events.host.description'),
    logo: () => (
      <IconLord
        src={LordIconSource.loadBalancer}
        trigger={LordIconTrigger?.loop}
        revert={false}
        delay={500}
        size={65}
        loading="lazy"
      />
    ),
    anchor: {
      href: 'https://host.it/',
      rel: 'nofollow',
      title: t('home.epsilon.timeline.events.host.anchor.title'),
      'aria-label': t('home.epsilon.timeline.events.host.anchor.aria-label'),
      target: '_blank'
    }
  },
  {
    date: '2023-02-01' as unknown as Date,
    title: t('home.epsilon.timeline.events.storage.title'),
    description: t('home.epsilon.timeline.events.storage.description'),
    logo: () => (
      <IconLord
        src={LordIconSource.server}
        trigger={LordIconTrigger?.loop}
        revert={false}
        delay={500}
        size={65}
        loading="lazy"
      />
    ),
    anchor: {
      href: 'https://storage.rottigni.tech/',
      rel: 'nofollow',
      title: t('home.epsilon.timeline.events.storage.anchor.title'),
      'aria-label': t('home.epsilon.timeline.events.storage.anchor.aria-label'),
      target: '_blank'
    }
  },
  {
    date: '2023-02-15' as unknown as Date,
    title: t('home.epsilon.timeline.events.cv.title'),
    description: t('home.epsilon.timeline.events.cv.description'),
    logo: () => (
      <IconLord
        src={LordIconSource.cv}
        trigger={LordIconTrigger?.loop}
        revert={false}
        delay={500}
        size={65}
        loading="lazy"
      />
    ),
    anchor: {
      href: 'https://cv.rottigni.tech/',
      rel: 'nofollow',
      title: t('home.epsilon.timeline.events.cv.anchor.title'),
      'aria-label': t('home.epsilon.timeline.events.cv.anchor.aria-label'),
      target: '_blank'
    }
  },
  {
    date: '2023-03-01' as unknown as Date,
    title: t('home.epsilon.timeline.events.portfolio.title'),
    description: t('home.epsilon.timeline.events.portfolio.description'),
    logo: () => (
      <IconLord
        src={LordIconSource.webApp}
        trigger={LordIconTrigger?.loop}
        revert={false}
        delay={500}
        size={65}
        loading="lazy"
      />
    )
  },
  {
    date: '2023-04-15' as unknown as Date,
    title: t('home.epsilon.timeline.events.data-science-course.title'),
    description: t('home.epsilon.timeline.events.data-science-course.description'),
    logo: () => <IconYoutube width={75} />,
    anchor: {
      href: 'https://www.youtube.com/@PieroSavastano',
      title: t('home.epsilon.timeline.events.data-science-course.anchor.title'),
      'aria-label': t('home.epsilon.timeline.events.data-science-course.anchor.aria-label'),
      rel: 'nofollow',
      target: '_blank'
    }
  },
  {
    date: '2023-06-06' as unknown as Date,
    title: t('home.epsilon.timeline.events.machine-learning-bootcamp.title'),
    description: t('home.epsilon.timeline.events.machine-learning-bootcamp.description'),
    logo: () => <IconUdemy width={150} />,
    anchor: {
      href: useLocaleThemePath('/certs/python-for-data-science-and-machine-learning-bootcamp'),
      title: t('home.epsilon.timeline.events.machine-learning-bootcamp.anchor.title'),
      'aria-label': t('home.epsilon.timeline.events.machine-learning-bootcamp.anchor.aria-label'),
      target: '_blank'
    }
  },
  {
    date: '2023-06-15' as unknown as Date,
    title: t('home.epsilon.timeline.events.raas.title'),
    description: t('home.epsilon.timeline.events.raas.description'),
    logo: () => <IconDeesup width="100" />
  },
  {
    date: '2023-06-29' as unknown as Date,
    title: t('home.epsilon.timeline.events.rottigni-tech.title'),
    description: t('home.epsilon.timeline.events.rottigni-tech.description'),
    logo: () => (
      // @ts-expect-error type mismatch
      <UnoIcon class={`${MDIIcon?.svelte} text-6xl text-secondary`} />
    ),
    anchor: {
      href: 'https://www.rottigni.tech',
      title: t('home.epsilon.timeline.events.rottigni-tech.anchor.title'),
      'aria-label': t('home.epsilon.timeline.events.rottigni-tech.anchor.aria-label'),
      target: '_blank'
    }
  },
  {
    date: '2023-07-06' as unknown as Date,
    title: t('home.epsilon.timeline.events.inmemory-release.title'),
    description: t('home.epsilon.timeline.events.inmemory-release.description'),
    logo: () => <IconDeesup width="100" />
  },
  {
    date: '2023-07-24' as unknown as Date,
    title: t('home.epsilon.timeline.events.jupyterhub.title'),
    description: t('home.epsilon.timeline.events.jupyterhub.description'),
    logo: () => (
      // @ts-expect-error type mismatch
      <UnoIcon class={`${MDIIcon?.jupyter} text-6xl text-secondary`} />
    ),
    anchor: {
      href: 'https://jupyterhub.rottigni.tech',
      title: t('home.epsilon.timeline.events.jupyterhub.anchor.title'),
      'aria-label': t('home.epsilon.timeline.events.jupyterhub.anchor.aria-label'),
      target: '_blank'
    }
  },
  {
    date: '2023-08-10' as unknown as Date,
    title: t('home.epsilon.timeline.events.smtp.title'),
    description: t('home.epsilon.timeline.events.smtp.description'),
    logo: () => (
      // @ts-expect-error type mismatch
      <UnoIcon class={`${MDIIcon?.fastify} text-6xl text-secondary`} />
    ),
    anchor: {
      href: 'https://smtp.rottigni.tech',
      title: t('home.epsilon.timeline.events.smtp.anchor.title'),
      'aria-label': t('home.epsilon.timeline.events.smtp.anchor.aria-label'),
      target: '_blank'
    }
  },
  {
    date: '2023-09-09',
    title: t('home.epsilon.timeline.events.vendure-scalapay.title'),
    description: t('home.epsilon.timeline.events.vendure-scalapay.description'),
    logo: () => <IconVendure className="w-12 h-12" lazy={true} />,
    anchor: {
      href: 'https://www.npmjs.com/package/vendure-scalapay',
      title: t('home.epsilon.timeline.events.vendure-scalapay.anchor.title'),
      'aria-label': t('home.epsilon.timeline.events.vendure-scalapay.anchor.aria-label'),
      target: '_blank'
    }
  },
  {
    date: '2023-09-15',
    title: t('home.epsilon.timeline.events.nlp.title'),
    description: t('home.epsilon.timeline.events.nlp.description'),
    logo: () => <IconUdemy width={150} />,
    anchor: {
      href: useLocaleThemePath('/certs/python-for-natural-language-processing'),
      title: t('home.epsilon.timeline.events.nlp.anchor.title'),
      'aria-label': t('home.epsilon.timeline.events.nlp.anchor.aria-label'),
      target: '_blank'
    }
  },
  {
    date: '2023-09-22',
    title: t('home.epsilon.timeline.events.sentry.title'),
    description: t('home.epsilon.timeline.events.sentry.description'),

    logo: () => (
      // @ts-expect-error type mismatch
      <UnoIcon class={`${MDIIcon?.grafana} text-6xl text-secondary`} />
    ),
    anchor: {
      href: 'https://sentry.rottigni.tech',
      title: t('home.epsilon.timeline.events.sentry.anchor.title'),
      'aria-label': t('home.epsilon.timeline.events.sentry.anchor.aria-label'),
      target: '_blank'
    }
  },
  {
    date: '2023-11-16',
    title: t('home.epsilon.timeline.events.k8s.title'),
    description: t('home.epsilon.timeline.events.k8s.description'),
    logo: () => <IconUdemy width={150} />,
    anchor: {
      href: useLocaleThemePath('/certs/k8s-mastery'),
      title: t('home.epsilon.timeline.events.k8s.anchor.title'),
      'aria-label': t('home.epsilon.timeline.events.k8s.anchor.aria-label'),
      target: '_blank'
    }
  },
  {
    date: '2024-02-02',
    title: t('home.epsilon.timeline.events.k8s-migration.title'),
    description: t('home.epsilon.timeline.events.k8s-migration.description'),
    logo: () => (
      // @ts-expect-error type mismatch
      <UnoIcon class={`${MDIIcon?.k8s} text-6xl text-secondary`} />
    ),
    anchor: {
      href: 'https://www.linkedin.com/pulse/helm-bare-metal-kubernetes-cluster-lorenzo-rottigni-cssff/',
      title: t('home.epsilon.timeline.events.k8s-migration.anchor.title'),
      'aria-label': t('home.epsilon.timeline.events.k8s-migration.anchor.aria-label'),
      target: '_blank'
    }
  },
  {
    date: '2023-05-08',
    title: t('home.epsilon.timeline.events.computer-vision.title'),
    description: t('home.epsilon.timeline.events.computer-vision.description'),
    logo: () => <IconUdemy width={150} />,
    anchor: {
      href: useLocaleThemePath('/certs/python-for-computer-vision'),
      title: t('home.epsilon.timeline.events.computer-vision.anchor.title'),
      'aria-label': t('home.epsilon.timeline.events.computer-vision.anchor.aria-label'),
      target: '_blank'
    }
  },
  {
    date: '2024-06-21',
    title: t('home.epsilon.timeline.events.vendure-article.title'),
    description: t('home.epsilon.timeline.events.vendure-article.description'),
    logo: () => <IconVendure />,
    anchor: {
      href: 'https://www.linkedin.com/pulse/vendure-backbone-deesups-growth-deesup-vckvf',
      title: t('home.epsilon.timeline.events.vendure-article.anchor.title'),
      'aria-label': t('home.epsilon.timeline.events.vendure-article.anchor.aria-label'),
      target: '_blank'
    }
  },
  {
    date: '2024-06-27',
    title: t('home.epsilon.timeline.events.google-cloud-summit.title'),
    description: t('home.epsilon.timeline.events.google-cloud-summit.description'),
    logo: () => (
      // @ts-expect-error type mismatch
      <UnoIcon class={`${MDIIcon?.google} text-6xl text-secondary`} />
    ),
    anchor: {
      href: 'https://cloudonair.withgoogle.com/events/summit-milan-24',
      title: t('home.epsilon.timeline.events.google-cloud-summit.anchor.title'),
      'aria-label': t('home.epsilon.timeline.events.google-cloud-summit.anchor.aria-label'),
      target: '_blank'
    }
  },
  {
    date: '2024-07-15',
    title: t('home.epsilon.timeline.events.k3s.title'),
    description: t('home.epsilon.timeline.events.k3s.description'),
    logo: () => (
      // @ts-expect-error type mismatch
      <UnoIcon class={`${MDIIcon?.k3s} text-6xl text-secondary`} />
    ),
    anchor: {
      href: 'https://k3s.io/',
      title: t('home.epsilon.timeline.events.k3s.anchor.title'),
      'aria-label': t('home.epsilon.timeline.events.k3s.anchor.aria-label'),
      target: '_blank'
    }
  },
  {
    date: '2024-07-(16-18)',
    title: t('home.epsilon.timeline.events.wad.title'),
    description: t('home.epsilon.timeline.events.wad.description'),
    logo: () => <IconWAD width={100} class="mb-2" />,
    anchor: {
      href: 'https://www.wearedevelopers.com/',
      title: t('home.epsilon.timeline.events.wad.anchor.title'),
      'aria-label': t('home.epsilon.timeline.events.wad.anchor.aria-label'),
      target: '_blank'
    }
  }
]

const downKeyIcon: LordIcon = {
  src: LordIconSource?.downKey,
  trigger: LordIconTrigger?.hoverLoop,
  revert: true,
  delay: 500,
  loading: 'lazy',
  size: 65
}

const upKeyIcon: LordIcon = {
  src: LordIconSource?.upKey,
  trigger: LordIconTrigger?.hoverLoop,
  revert: true,
  delay: 500,
  loading: 'lazy',
  size: 65
}

function scrollBottom() {
  if (timelineEl.value?.clientHeight)
    timelineEl.value.scrollBy({
      top: timelineEl.value.clientHeight * 12,
      left: 0,
      behavior: 'smooth'
    })
}

function scrollTop() {
  timelineEl.value?.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
}

function play() {
  if (timelineEl.value?.clientHeight)
    timelineEl.value.scrollBy({
      top: timelineEl.value.clientHeight * 12,
      left: 0,
      behavior: 'smooth'
    })
  setTimeout(() => timelineEl.value?.scrollTo({ top: 0, left: 0, behavior: 'smooth' }), 1200)
}

onMounted(() => {
  isMobile.value = xs || sm || false
  scrollTop()
})
</script>

<template lang="pug">
section.home__epsilon
  h6 {{ $t('home.epsilon.timeline.title') }}
  .home__epsilon__timeline__player
    span(@click="scrollTop")
      IconLord(v-bind="upKeyIcon")
    span(@click="play")
      UnoIcon(:class="`${MDIIcon?.play} hover:rotate-[360deg]`")
    span(@click="scrollBottom")
      IconLord(v-bind="downKeyIcon")
  .home__epsilon__timeline(ref="timelineEl")
    ul(:style="`height: calc(${isMobile ? '55vh' : '25vh'} * ${timeline?.length})`")
      li(
        v-for="(event, index) in timeline"
        :key="`timeline-event-${index}`"
        :style="`top: calc((100% / ${timeline?.length}) * ${index})`"
        :class="(isMobile) ? 'right' : (index % 2 === 0) ? 'left' : 'right'"
      )
        component(
          :is=" \
            event.anchor?.to \
            ? nuxtLink \
            : event.anchor?.href ? 'a' : 'article' \
          "
          v-bind="event?.anchor ? event.anchor : {}"
        )
          .home__epsilon__time__event__link
            time(:datetime="event.date") {{ event.date }}
            .gear
              UnoIcon(:class="`${MDIIcon?.gear} text-4xl`")
          .home__epsilon__time__event__content
            component(:is="event.logo")
            span {{ event.title }}
            p(v-if="event.description" v-html="event.description")
</template>

<style lang="sass" scoped>
section.home__epsilon
  @apply w-full relative
  h6
    @apply w-full text-center text-primary text-2xl xl:text-3xl font-bold whitespace-nowrap mt-28 mb-6 text-animated
  .home__epsilon__timeline
    @apply w-full h-[75vh] overflow-y-auto overflow-x-hidden flex items-start justify-end lg:justify-center text-primary pt-16 lg:pt-24 pb-48 lg:pb-80 relative
    scrollbar-width: none
    -ms-overflow-style: none
    &::-webkit-scrollbar
      @apply hidden
    ul
      @apply w-4 bg-gradient-theme-1 translate-x-[50%] relative mr-6 lg:mr-0 rounded-b-[50px]
      li
        @apply absolute w-[280px] xl:w-[350px] 2xl:w-[420px] cursor-pointer
        article,
        a
          @apply w-full flex justify-between no-underline
          span
            @apply whitespace-normal text-base font-bold text-primary
          p
            @apply text-xs text-white mt-2 max-h-[170px] lg:max-h-full overflow-y-auto scrollbar-primary
          .home__epsilon__time__event__content
            @apply w-full p-4 border-t border-primary bg-transparent-dark hover:bg-dark-800 flex flex-col items-start transition-all
          .home__epsilon__time__event__link
            @apply w-14 xl:w-28 2xl:w-36 h-3 bg-primary flex-shrink-0 relative
            .gear
              @apply absolute w-12 h-12 rounded-[50%] bg-dark-900 border-2 border-dashed border-secondary z-10 mt-1
              & > *
                @apply animate-spin text-secondary
            time
              @apply absolute left-[-50%] lg:left-[50%] top-0 whitespace-nowrap text-secondary

        // &:nth-child(odd)
        &.left
          @apply left-0
          .home__epsilon__time__event__content
            @apply border-l rounded-br-2xl order-2
          .home__epsilon__time__event__link
            @apply order-1
            .gear
              @apply left-0 ml-2
              transform: translate(-50%, -50%)
            time
              transform: translate(0%, -150%)
        // &:nth-child(even)
        &.right
          @apply right-0 mr-2
          .home__epsilon__time__event__content
            @apply border-r rounded-bl-2xl order-1
          .home__epsilon__time__event__link
            @apply order-2
            .gear
              @apply right-0
              transform: translate(50%, -50%)
            time
              transform: translate(-100%, -150%)
  .home__epsilon__timeline__player
    @apply absolute right-0 top-[50%] translate-y-[-50%] hidden lg:flex-center flex-col border border-primary rounded-2xl bg-black z-10 text-primary
    span
      @apply cursor-pointer
      & > *
        @apply transition-all
// @media screen and (min-width: 700px)
//   section.home__epsilon .home__epsilon__timeline ul li:nth-child(odd)
//     @apply left-0
//     .home__epsilon__time__event__content
//       @apply border-l rounded-br-2xl
//     .home__epsilon__time__event__link
//       &::after
//         @apply left-0
//         transform: translate(-25%, -50%)
</style>
