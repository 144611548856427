<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useRoot } from '~/store'

const { lineDrawing } = defineProps({
  lineDrawing: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const { theme } = storeToRefs(useRoot())
</script>

<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="1280.000000pt"
    height="1280.000000pt"
    viewBox="0 0 1280.000000 1280.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" fill="#292524" stroke="none">
      <path
        d="M5680 12780 c-251 -30 -440 -61 -440 -74 0 -6 9 -45 20 -86 57 -214
17 -396 -118 -542 -133 -141 -322 -190 -504 -130 -180 59 -311 210 -363 417
-10 39 -21 73 -25 78 -9 11 -349 -123 -553 -218 -55 -26 -57 -28 -44 -48 92
-143 122 -233 122 -367 0 -117 -22 -200 -74 -281 -95 -150 -278 -241 -453
-225 -184 17 -344 127 -443 304 -22 40 -44 72 -49 72 -18 0 -285 -198 -434
-322 l-83 -70 62 -66 c151 -161 209 -324 179 -501 -34 -204 -197 -367 -401
-401 -177 -30 -336 27 -501 179 l-67 61 -69 -82 c-125 -151 -322 -416 -322
-434 0 -5 33 -27 72 -49 178 -99 287 -259 304 -443 13 -138 -41 -284 -139
-381 -108 -106 -207 -146 -367 -146 -134 0 -224 30 -367 122 -20 13 -22 11
-48 -44 -89 -194 -229 -542 -221 -551 3 -2 42 -15 88 -28 127 -37 202 -78 278
-153 296 -292 152 -760 -260 -847 -88 -19 -165 -14 -287 17 -46 12 -86 20 -88
18 -9 -10 -84 -573 -85 -636 0 -10 25 -13 93 -13 109 0 222 -25 300 -67 70
-37 186 -155 220 -223 98 -199 54 -429 -111 -582 -110 -101 -239 -148 -409
-148 -68 0 -93 -3 -93 -12 1 -64 76 -627 85 -637 2 -2 42 6 88 18 170 43 288
35 424 -29 186 -87 308 -309 275 -503 -38 -231 -191 -389 -437 -452 -38 -10
-73 -21 -78 -25 -12 -10 159 -437 229 -572 l19 -36 75 44 c119 69 187 89 310
89 117 0 200 -22 281 -74 150 -95 241 -278 225 -453 -17 -184 -127 -344 -304
-443 -40 -22 -72 -44 -72 -49 0 -18 198 -285 323 -434 l70 -84 51 49 c116 113
183 153 303 184 91 23 156 24 242 4 191 -45 339 -203 371 -396 30 -177 -27
-336 -179 -501 l-61 -67 82 -69 c151 -125 416 -322 434 -322 5 0 27 33 49 72
99 178 259 287 443 304 175 16 358 -75 453 -225 52 -81 74 -164 74 -281 0
-134 -30 -224 -122 -367 -14 -20 -12 -23 34 -43 259 -117 554 -234 563 -222 4
4 15 39 25 77 52 207 183 358 363 417 182 60 371 11 504 -130 70 -76 112 -157
134 -263 19 -87 14 -158 -17 -283 -12 -47 -20 -88 -18 -90 11 -10 571 -85 642
-86 4 0 7 42 7 93 0 170 47 299 148 409 201 217 523 217 724 0 101 -110 148
-239 148 -409 0 -51 3 -93 8 -93 70 1 630 76 641 86 2 2 -6 41 -18 87 -31 122
-36 199 -17 286 23 108 66 192 136 265 134 140 322 187 502 128 180 -59 311
-210 363 -417 10 -38 21 -73 25 -77 9 -12 305 105 563 222 l48 21 -19 32 c-87
145 -111 211 -119 327 -21 309 230 563 529 536 184 -17 344 -126 443 -304 22
-39 44 -72 49 -72 18 0 277 193 434 323 l83 69 -62 66 c-120 128 -175 242
-186 387 -18 247 161 474 408 515 178 30 336 -26 501 -179 l67 -61 68 82 c130
157 323 416 323 434 0 5 -33 27 -72 49 -134 75 -227 180 -274 311 -124 342
139 686 506 661 102 -7 179 -32 280 -91 l75 -44 14 26 c64 118 246 572 234
582 -5 4 -39 15 -78 25 -119 30 -210 79 -285 154 -296 296 -153 759 261 847
87 19 164 14 286 -17 46 -12 85 -20 87 -18 10 11 85 573 86 637 0 9 -25 12
-93 12 -170 0 -299 47 -409 148 -106 99 -161 222 -161 362 0 140 55 263 161
362 110 101 239 148 409 148 68 0 93 3 93 13 -1 63 -76 626 -85 636 -2 2 -42
-6 -88 -18 -122 -31 -199 -36 -287 -17 -220 46 -380 209 -411 416 -24 157 30
312 151 431 76 75 151 116 278 153 46 13 85 26 88 28 13 13 -237 605 -254 601
-4 -1 -40 -20 -79 -42 -97 -57 -174 -81 -273 -88 -309 -21 -563 231 -536 529
17 184 126 344 304 443 39 22 72 44 72 49 0 18 -197 283 -322 434 l-69 82 -67
-61 c-165 -153 -323 -209 -501 -179 -205 34 -367 196 -401 401 -30 178 28 340
179 501 l62 66 -83 70 c-149 124 -416 322 -434 322 -5 0 -27 -32 -49 -72 -99
-177 -259 -287 -443 -304 -175 -16 -358 75 -453 225 -52 81 -74 164 -74 281 0
123 20 191 89 310 l44 75 -46 24 c-134 69 -552 236 -562 224 -4 -5 -15 -39
-25 -78 -52 -207 -183 -358 -363 -417 -182 -60 -371 -11 -504 130 -135 146
-175 328 -118 542 11 41 20 80 20 86 0 13 -192 45 -450 74 -96 11 -181 20
-187 20 -10 0 -13 -25 -13 -93 0 -170 -47 -299 -148 -409 -201 -217 -523 -217
-724 0 -101 110 -148 239 -148 409 l0 93 -27 -1 c-16 -1 -98 -9 -183 -19z
m797 -915 c87 -26 150 -104 160 -199 7 -70 -11 -129 -54 -177 -65 -72 -147
-100 -233 -79 -68 16 -119 56 -154 119 -25 47 -28 60 -24 125 4 85 33 139 98
182 66 44 127 53 207 29z m198 -1200 c333 -16 728 -93 1080 -211 1674 -561
2844 -2111 2913 -3861 5 -106 5 -280 0 -385 -65 -1650 -1109 -3131 -2653
-3765 -840 -346 -1803 -411 -2685 -182 -1493 388 -2675 1565 -3065 3054 -184
702 -184 1468 0 2170 315 1201 1160 2227 2279 2766 522 251 1084 393 1656 419
161 7 230 6 475 -5z m-3860 -354 c73 -40 115 -113 115 -198 0 -35 -6 -78 -14
-97 -39 -93 -138 -152 -239 -143 -191 17 -284 242 -160 387 78 91 190 110 298
51z m7369 17 c101 -31 180 -161 158 -262 -25 -120 -114 -196 -230 -196 -34 0
-77 6 -96 14 -92 39 -152 138 -143 238 14 156 158 252 311 206z m-8951 -3699
c47 -13 112 -70 137 -120 58 -113 18 -245 -93 -309 -84 -48 -193 -40 -270 20
-65 51 -99 158 -77 241 36 134 166 206 303 168z m10498 -13 c51 -21 86 -53
116 -103 22 -38 27 -59 27 -112 1 -75 -17 -120 -66 -170 -43 -44 -80 -62 -142
-68 -70 -7 -129 11 -177 54 -127 115 -111 300 34 383 42 24 64 30 113 30 34 0
77 -6 95 -14z m-8947 -3700 c93 -39 152 -138 143 -239 -18 -201 -253 -288
-398 -148 -153 148 -52 401 159 401 34 0 77 -6 96 -14z m7444 -23 c128 -79
149 -254 43 -364 -148 -153 -401 -52 -401 159 0 103 44 176 135 219 69 33 156
28 223 -14z m-3764 -1507 c103 -31 166 -116 166 -224 0 -95 -37 -162 -113
-206 -98 -56 -200 -43 -283 38 -94 92 -101 224 -17 317 71 79 152 103 247 75z"
      />
      <path
        d="M6033 9874 c-818 -89 -1558 -450 -2133 -1040 -565 -580 -908 -1324
-979 -2121 -14 -156 -14 -470 0 -626 139 -1564 1328 -2856 2877 -3127 380 -67
825 -67 1206 0 464 81 916 262 1316 525 354 233 704 574 946 921 344 494 559
1083 614 1684 13 148 13 472 0 620 -101 1112 -727 2108 -1689 2688 -429 259
-922 422 -1436 477 -173 18 -552 18 -722 -1z m732 -489 c361 -47 668 -140 986
-301 581 -294 1046 -760 1339 -1343 l48 -98 -468 -468 c-258 -258 -471 -467
-473 -465 -2 3 -10 34 -17 70 -7 36 -30 119 -52 184 -310 957 -1337 1477
-2298 1165 -176 -57 -375 -162 -528 -277 -40 -30 -477 -460 -975 -959 l-902
-904 -7 33 c-4 18 -12 103 -19 188 -27 361 26 775 147 1139 313 936 1075 1668
2022 1941 201 57 420 96 647 114 100 8 433 -3 550 -19z m-184 -1991 c216 -41
389 -134 541 -293 124 -128 192 -241 241 -396 115 -364 14 -760 -260 -1026
-132 -128 -278 -211 -461 -261 -111 -31 -373 -31 -484 0 -340 93 -606 337
-715 657 -176 517 95 1082 613 1276 150 56 364 74 525 43z m2819 -809 c12
-159 9 -330 -11 -510 -117 -1093 -834 -2042 -1858 -2460 -802 -327 -1697 -292
-2476 98 -575 287 -1054 766 -1344 1345 l-49 99 468 468 c258 258 471 467 473
465 2 -3 10 -34 17 -70 7 -36 30 -119 52 -184 104 -324 297 -610 553 -824 557
-466 1312 -563 1963 -252 116 55 266 151 357 227 33 27 458 449 945 937 l885
887 7 -33 c3 -18 12 -105 18 -193z"
      />
    </g>
  </svg>
</template>

<style lang="sass" scoped>
svg
  @apply w-full h-full opacity-75
</style>
