<script lang="tsx" setup>
import { number, object, string } from 'yup'
import type { ListItem } from '~/types/custom'
import { LordIconSource, LordIconTrigger, MDIIcon } from '~/types/assets'
import type { LordIcon } from '~/types/assets'
import { useRoot } from '~/store'

interface ReviewForm {
  fullName: string | null
  role: ListItem | null
  email: string | null
  rating: number | null
  review: string | null
}

const UnoIcon = resolveComponent('UnoIcon')

useLocalizedValidations()
useValidationHooks()

const { openToast } = useRoot()
const { t } = useI18n()
const roles = useFormRoles()

const schema = object({
  fullName: string().required().min(5),
  role: object().required(),
  email: string().required().email(),
  review: string().required().min(30),
  rating: number().required(),
}).required()

const initialValues: ReviewForm = {
  role: roles[0],
  fullName: null,
  review: null,
  email: null,
  rating: 4,
}

const iconDeveloper: LordIcon = {
  src: LordIconSource?.developer,
  trigger: LordIconTrigger?.loop,
  loading: 'lazy',
  revert: false,
  delay: 500,
  size: 70,
}

async function onSubmit(submission: ReviewForm, { resetForm }: { resetForm: () => void }) {
  try {
    await $fetch('/api/mail', {
      method: 'POST',
      body: {
        to: 'lorenzo@rottigni.tech',
        subject: `REVIEW - ${submission.email}`,
        text: `${submission.fullName}   |  ${submission.rating}  |  ${submission.review}`,
        html: `<h1>${submission.fullName}</h1><div><span>${submission.rating}</span></div><p>${submission.review}</p>`,
      },
    })

    const toast = markRaw(
      defineComponent({
        render: () => (
          <div class="flex items-center gap-3">
            {/* @ts-expect-error type mismatch */}
            <UnoIcon class={MDIIcon.success} />
            <p>{t('home.beta.toast')}</p>
          </div>
        ),
      }),
    )
    openToast(toast)

    resetForm()
  } catch (err) {
    console.error(err)
  }
}
</script>

<template lang="pug">
section.home__beta
  .home__beta__mobile
    IconLord(v-bind="iconDeveloper")
    h3 {{ $t('home.alpha.text-content.0') }}
    UIParagraph(
      updated-at="2023-04-04"
    ) {{ $t('home.alpha.text-content.1') }}
  KinesisContainer.home__beta__desktop
    KinesisElement(tag="h3" type="depth") {{ $t('home.beta.title') }}
    KinesisElement(tag="p" :strength="30") {{ $t('home.beta.text-content') }}
    KinesisElement(:strength="10")
      VForm.v-form(
        v-slot="{ values }"
        :initial-values="initialValues",
        :validation-schema="schema",
        data-test="form"
        @on-invalid-submit="useInvalidSubmit",
        @on-submit="onSubmit"
      )
        .v-form-row
          .v-form-item
            label.v-form-item__label(
              for="form-full-name-input"
            ) {{ $t('home.beta.form.full-name.label') }}
            VField.v-form-item__field(
              id="form-full-name-input"
              name="fullName"
              data-test="full-name"
              type="text"
              :placeholder="$t('home.beta.form.full-name.placeholder')"
            )
            VErrorMessage.v-form-item__error(
              name="fullName",
              as="p"
            )
          .v-form-item
            label.v-form-item__label(
              for="form-role-input"
            ) {{ $t('home.beta.form.role.label') }}
            UIDropdown(
              id="form-role-input"
              name="role"
              data-test="role"
              :fluid="true"
              :options="roles"
              :option="values.role || roles[0]"
              @update:option="(e) => values.role = e"
            )
            VErrorMessage.v-form-item__error(
              name="role"
              as="p"
            )
        .v-form-row
          .v-form-item
            label.v-form-item__label(
              for="form-email-input"
            ) {{ $t('home.beta.form.email.label') }}
            VField.v-form-item__field(
              id="form-email-input"
              name="email"
              type="email"
              data-test="email"
              placeholder="mario@rossi.com"
            )
            VErrorMessage.v-form-item__error(
              name="email",
              as="p"
            )
          .v-form-item
            label.v-form-item__label(
              for="form-rating-input"
            ) {{ $t('home.beta.form.rating.label') }}
            ul.v-form-item__rating
              li(
                v-for="(star, index) in 5"
                :key="`star-${index}`"
                v-tooltip="`${star} / 5`"
                @click="values.rating = star"
              )
                // !() cause of vite parser error when using &lt; operator
                UnoIcon(
                  :class="`${MDIIcon.decagram} ${!(star > values.rating) ? 'text-secondary' : 'text-dark-500'}`"
                )
        .v-form-row
          .v-form-item.__fluid
            label.v-form-item__label(
              for="form-review-input"
            ) {{ $t('home.beta.form.review.label') }}
            VField.v-form-item__field(
              id="form-review-input",
              name="review",
              data-test="review"
              :placeholder="$t('home.beta.form.review.placeholder')"
              as="textarea"
              rows="3"
            )
            VErrorMessage.v-form-item__error(
              name="review",
              as="p"
            )
        .v-form-row.justify-center.mt-6
          UIButton(
            class-name="bg-secondary text-dark-900 hover:bg-dark-900 hover:text-secondary border-y border-dark-900 hover:border-primary"
            :binding="{ type: 'submit', 'data-test': 'submit' }"
          ) {{ $t('home.beta.form.submit') }}
</template>

<style lang="sass" scoped>
section.home__beta
  @apply w-full h-full
  .home__beta__mobile
    @apply w-full h-full flex-center flex-col lg:hidden
    h3
      @apply text-xl text-center pb-6 pt-2 text-animated font-bold
  .home__beta__desktop
    @apply w-full h-full hidden lg:flex-center flex-col
    h3
      @apply text-3xl text-primary text-center text-animated font-bold
    p
      @apply w-10/12 mx-auto mt-4 text-sm xl:text-base 2xl:text-lg text-white text-center
    form
      @apply w-10/12 mx-auto mt-4 xl:mt-6
      textarea
        @apply scrollbar-primary
      p.v-form-item__error
        @apply text-left text-sm text-secondary p-0 m-0
      ul.v-form-item__rating
        @apply flex
        li
          @apply cursor-pointer hover:rotate-90 transition-all
</style>
